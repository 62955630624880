import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <div class="error-page">
        <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        {/* <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
        <div className="gif-feed">
          <video width="100%" autoplay="true" loop="true"
            src="https://media.giphy.com/media/UoeaPqYrimha6rdTFV/source.mp4">
          </video>
        </div>

        <div className="button-feed">
        <a class="gif-button" href="/">
          Come Back Home
        </a>
        </div>
        
      
      </Layout>
      </div>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
